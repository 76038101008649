.homeContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
  overflow: auto;
}
.content {
  width: calc(100% - 90px);
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: 0;
  margin-top: 20px;
  transition: all 0.5s;
}
.content.active {
  width: calc(100% - 285px);
}

.container {
  width: 85%;
  margin: auto;
}

.menuBar {
  padding: 0px !important;
}
.homeContainer .placeBanner {
  height: 400px;
  position: relative;
}

.placeBanner > img {
  height: 400px;
}
.placeSearch {
  position: absolute;
  top: 0;
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.placeSearch > div {
  width: 60%;
  background: #00000078;
  border-radius: 4px;
  padding: 20px 25px;
}

.propertyTypes,
.topCities,
.propertyList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.propertyType,
.topCity,
.property {
  width: 30%;
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0px 3px 10px #adadad;
  border-radius: 4px;
}
.propertyType > img,
.topCity > img {
  border-radius: 4px;
  height: 100%;
}
.propertyType > h4,
.topCity > h4 {
  position: absolute;
  bottom: 0;
  margin: 0px;
  background: #00000099;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  width: 100%;
  color: #fff;
  font-size: 14px;
  padding-top: 20px;
  padding-bottom: 12px;
  text-align: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  font-weight: 600;
}

.property > div.infoBox > div {
  display: flex;
  margin-top: 5px;
}
.property > div.infoBox > div svg {
  color: var(--primaryColor);
  position: relative;
  left: -3px;
  font-size: 16px;
}
.property > div.infoBox {
  padding-left: 8px;
  padding-bottom: 6px;
}
.property > div {
  overflow: hidden;
}
.property h4 {
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 16px;
  color: var(--primaryColor);
}
.property p {
  margin-top: 0px;
  margin-bottom: 1px;
  font-size: 14px;
}

div#mostViewedList {
  margin-bottom: 30px;
}
.mostViewed {
  margin-bottom: 20px;
  position: relative;
  box-shadow: 0px 3px 8px #ccc;
  border-radius: 4px;
  cursor: pointer;
}
.mostViewed > div {
  display: flex;
  border-radius: 5px;
}
.mostViewed img {
  border-radius: 4px;
}
.mostViewed:hover .mostViewedInfo {
  opacity: 1;
}
.mostViewedInfo {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #0000009e;
  color: #fff;
  transition: all 1s;
  opacity: 0;
  border-radius: 4px;
}
.mostViewedInfo h4 {
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 20px;
}
.mostViewedInfo p {
  margin: 0px;
  margin-bottom: 5px;
  font-size: 14px;
}

.searchCity ul {
  padding: 0px !important;
}
.searchCity li {
  padding: 0px !important;
  font-size: 12px !important;
}
.placeSearch .uiAutoTxt > div > div {
  height: 45px;
}
.placeSearch .uiAutoTxt input {
  margin-top: 4px;
  font-size: 15px;
}
