.agreementContent {
  padding: 1rem 3.5rem 2rem 3.5rem;
  font-weight: 300;
  font-size: 1.2rem;
  text-align: justify;
  height: 85%;
  overflow-y: scroll;
}

.agreementFooter {
  position: sticky;
  bottom: 0;
  background-color: ghostwhite;
  height: 10%;
  border: 1px solid black;
  padding-bottom: "1rem";
}
.agreementFlexCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.chkboxopt {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.subpointpadding {
  padding: 0.5rem 0rem 0.4rem 2rem;
}
.agreementContent li.bold {
  padding-top: 0.3rem;
}
.noBorder {
  border: none;
}
.borderRight {
  border-right: 1px solid black;
}
.signatureBox {
  border: 1px solid black;
  width: 30%;
  height: 12rem;
  margin: 1rem 0rem 1rem 0rem;
}
