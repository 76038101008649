.listIcon{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 1rem;
    margin-bottom: 1rem;
}
.formSection>.listIcon:last-child{
    margin-bottom: 0rem;
}
.listIcon>svg {
    color: var(--primaryColor);
    font-size: 1.5rem;
}
.listIcon>p {
    font-size: 1rem !important;
    font-weight: 300;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 0px;
}
.listIcon>p>span:first-child {
    display: block;
    width: 10%;
}
.listIcon>p>span:last-child {
    display: block;
    width: 80%;
}
