#roi input {
  border: none;
  outline: none;
  width: 80%;
  text-align: right;
}
#roi table {
  margin-top: 15px;
  border: 1px solid black;
  border-collapse: collapse;
  width: 100%;
}

#roi th,
#roi td {
  padding: 5px 5px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 15px;
  width: 15%;
}
#roi .mainHead {
  background-color: rgb(238, 238, 238);
}
#roi .tdata {
  border: 1px solid gray;
}
#roi .subHead {
  text-decoration: underline;
}
#roi .heading {
  padding-right: 50px;
}
#roi .empty {
  height: 25px;
}
#roi .roiEntry {
  background-color: #fffaad;
  color: black;
  text-align: right;
}
#roi .roiInvestment {
  background-color: #24b166;
  color: white;
  font-weight: bold;
  font-size: 1.6rem;
  text-align: right;
}
#roi .roiCalculations {
  background-color: #d6d8ff;
  color: #000;
  text-align: right;
}

#roi .logo-one {
  width: 40%;
}
#roi .logo-two {
  width: 100%;
}
#roi .image-div {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
}
.justify {
  display: flex;
}
#roi .table {
  width: 100%;
}

#roi .monthOperate {
  font-weight: bold;
}




.dashboardCard > h4{
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0rem 0;
}

.dashboardCard h4:nth-child(1)
{
  margin-top: 2rem;
}

.roiContainer{
   width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.roiLeft{
  width: 60%;
  background: white;
  height: 85%;

}

.roiRight{
  width:40%;
  background: white;
  height: 85%;

}

.dashboardWrapper{
  display:flex;
  width:100%;
  height:20%;
  justify-content: space-around;
}

.dashboardCard{
  border: 1px solid #d1d1d1 !important;
  width: 45%;
  border-radius: 4px !IMPORTANT;
  background-color: whitesmoke;
  box-shadow: 2px 2px 5px #ccc;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;
}


.imageWrapper{
  top:1rem;
  width: 100%;
  position: relative;
  background-size: 100%  100%;
  height: 16%;


}

.imageWrapper img{
  width: 99%;
  height: 100%; 
  position: absolute;
 
}

.carImageWrapper{
  width: 96%;
  display: flex;
  justify-content: space-around;
  position:absolute;
}

.carImageWrapper img{
  top:1.5rem;
  left: 1.5rem;
  position:relative;
  height: 5.935rem;


}

.dashboardCard li ul {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 0;
  margin-left: 0;
  padding-left: 4rem;
}
