.thumbnailBox {
    margin-top: 10px;
    position: relative;
  }
  
  .thumbnailBoxIcon{
    position: absolute !important;
    top: 4px;
    right: 4px;
    background-color: #fff !important;
    padding: 4px !important;
  }
  
  .thumbnailBoxIcon svg {
    font-size: 18px;
    color: red;
  }