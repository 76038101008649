:root {
  --primaryColor: #0034ad;
  --secondaryColor: #0080c8;
  --tertiaryColor: #cec225;
  --dimGray: #e1e1e1;
  --subTitleGray: #7c7c7c;
  --red: #ff3333;
  --green: #5cb85c;
  --gridHeaderBg: #f7f7f7;
  --gridHeaderColor: #5c6b65;
  --borderColor: #d8d8d8;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  margin: 0;
  position: relative;
  /* font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif; */
  font-family: "Mulish", sans-serif;
}
.uiRadioGroup label > span {
  /* font-family: "Inter", "Source Sans Pro", Helvetica, Arial, sans-serif; */
  font-family: "Mulish", sans-serif;
}

div#root {
  overflow-x: hidden;
  overflow-y: auto;
}

.historyBox {
  margin-bottom: 25px;
  border-left: 3px solid #888;
  padding-left: 8px;
  cursor: pointer;
}
.historyBox h4 {
  margin-bottom: 0px;
  font-size: 16px;
  margin-top: 0px;
  color: #888;
  font-weight: 500;
}
.historyBox p {
  margin-top: 4px;
  font-size: 14px;
  font-weight: 500;
  color: #888;
  text-transform: capitalize;
}
.historyBox.active {
  border-color: var(--primaryColor);
}
.historyBox.active h4,
.historyBox.active p {
  font-weight: bold;
  color: var(--primaryColor);
}

button.reportBtn {
  background: #fff;
  box-shadow: none;
  border: none;
  border-radius: 3px;
  padding: 6px 0px;
  font-size: 18px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  color: #0000008a;
  text-transform: uppercase;
  cursor: pointer;
  padding-left: 3px;
}

li.leftMenu.gantt svg {
  transform: rotate(90deg);
}

.formGroup button[disabled] > div {
  top: 7px;
  left: 14px;
}

button.uiBtn {
  font-size: 0.8rem !important;
  min-width: 8rem !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  font-weight: 600 !important;
}

.shadowTxt {
  text-align: center;
  font-weight: 500;
  margin-bottom: 0px;
  color: #fff;
  text-shadow: 1px 2px 4px #000;
}

.fontXL {
  font-size: 2.5rem;
}
.fontL {
  font-size: 2rem;
}
.fontM {
  font-size: 1.5rem;
}
.fontS {
  font-size: 1rem;
}

.bgHighlight{
  background-color: red;
}
.red {
  color: var(--red) !important;
}
.green {
  color: var(--green) !important;
}
.dim {
  color: #ccc;
}

.width100 {
  width: 100%;
}
.width70 {
  width: 70%;
}
.width60 {
  width: 60%;
}
.width50 {
  width: 50%;
}
.width45 {
  width: 45%;
}
.width30 {
  width: 30%;
}
.width25 {
  width: 25%;
}
.width23 {
  width: 23%;
}
.width20 {
  width: 20%;
}
.width10 {
  width: 10%;
}

.bgBanner {
  background-image: url(../assets/images/banner.png);
  /* background-position-x: 180%;
  background-position-y: 32%; */
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #acc1d2;
  /* background-position-y: 35%; */
  background-position-x: 70%;
}
.bgBanner .content {
  background: transparent;
  /* transition: none; */
}

.leftBar {
  height: 100%;
  width: 60px;
  background: #082058;
  /* background: transparent; */
  transition: all 0.6s;
  filter: drop-shadow(0px 0px 8px #000);
  position: fixed;
  left: 0;
  z-index: 1200;
  overflow: hidden;
  overflow-y: auto;
}
.leftBar.active {
  width: 250px;
}
.leftBar > ul {
  padding-top: 0px !important;
}
.leftBar > ul > li {
  margin-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 10px;
}
.leftBar > ul > li:not(:first-child) > div {
  display: flex;
  justify-content: center;
  padding-right: 10px;
  padding-left: 10px;
  width: 100%;
}
.leftBar > ul > li.active > div {
  background: var(--tertiaryColor);
  width: 100%;
  border-radius: 3px;
  padding: 3px 8px;
}
li.leftMenu > div > div:last-child {
  width: 100%;
  border-radius: 3px;
  padding: 5px 8px;
}
/* li.leftMenu.active > div > div:last-child {
   background: var(--tertiaryColor); 
} */
.leftBar > ul > li > div h4 {
  color: #fff;
  opacity: 0;
  transition: all 0.5s;
}
.leftBar.active > ul > li > div h4 {
  opacity: 1;
}
.leftBar svg {
  color: #ccc;
}
.leftBar > ul > li .menuText {
  opacity: 0;
  color: #fff;
  transition: all 0.5s;
}
.leftBar .menuText span {
  font-size: 14px;
  font-weight: 600;
  position: relative;
  top: 2px;
  color: #ccc;
  width: 100%;
}
.leftBar > ul > li.active .menuText span {
  color: var(--primaryColor);
  font-weight: 600;
  background: "#063855";
  width: 200;
  height: 32;
  border-radius: 16;
}
.leftBar.active > ul > li .menuText {
  opacity: 1;
}
.leftBar > ul > li.active svg {
  color: var(--primaryColor);
  padding-left: 0.2rem;
}
.leftBar > ul > li:first-child {
  border-bottom: 2px solid #b1b1b1;
  height: 55px;
  margin-bottom: 10px;
}
li.leftMenu > div {
  display: flex;
}
li.leftMenu.active > div {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.leftBar > ul > li:not(:first-child) > div > div:first-child {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.leftDropDownMenu {
  padding-left: 20px;
}
.leftBar.active .leftDropDownMenuIcon {
  left: 4px;
}
.leftBar .leftDropDownMenuIcon {
  left: -0.2rem;
}
.leftDropDownMenuIcon {
  min-width: 30px !important;
  position: relative;
}
.leftDropDownMenuText {
  text-align: left;
  color: #fff;
  margin-left: 1rem;
}
.leftDropDownMenuIcon svg {
  font-size: 1.5rem;
}
.leftBar li.leftMenu.subMenu {
  margin-left: 0.5rem;
}
.leftBar.active li.leftMenu.subMenu {
  margin-left: 2.2rem;
}
li.leftMenu.subMenu {
  display: flex;
  flex-direction: row;
  align-items: center;
}
li.leftMenu.subMenu > div {
  padding-left: 0.5rem;
}
li.leftMenu.subMenu svg {
  font-size: 1.2rem;
  margin-top: 0.4rem;
}
li.leftMenu.subMenu .menuText {
  margin-left: -1rem;
}
li.leftMenu.subMenu.active > div {
  background: var(--tertiaryColor);
  border-radius: 4px;
}
li.leftMenu.subMenu.active svg,
li.leftMenu.subMenu.active .menuText span {
  color: var(--primaryColor);
}

div[role="tooltip"] {
  z-index: 99999;
}

/* Data Grids */
.MuiDataGrid-columnHeader {
  background: var(--primaryColor);
  color: #fff;
  text-transform: uppercase;
}
.MuiDataGrid-columnHeaderTitle {
  font-weight: bold;
}
.dx-header-row {
  background: var(--primaryColor);
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
}

.uploadDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-bottom: 3px;
}
.uploadDiv > svg {
  font-size: 55px;
  margin-top: 5px;
  padding: 0px 10px;
  border-radius: 4px;
  box-shadow: 1px 1px 5px #c3c3c3;
  color: var(--secondaryColor);
}

a {
  color: var(--primaryColor) !important;
  text-decoration: none;
}
.container {
  width: 80%;
  margin: auto;
}
label.formLabel {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 2px;
  display: flex;
}
.textJustify {
  text-align: justify;
}
.bold {
  font-weight: bold;
}
.semiBold{
  font-weight: 600;
}
.yesIcon {
  color: #00bd00;
  font-size: 18px !important;
}
.noIcon {
  color: #ff0000;
  font-size: 18px;
}
.flexCenter {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.formGroup {
  margin-bottom: 15px;
  position: relative;
}
/* .formGroup > label {
  font-size: 14px;
  font-weight: 500;
} */

.uiChkBox svg {
  color: var(--primaryColor);
}
.pageInfo > h4 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
  color: var(--primaryColor);
  padding-bottom: 8px;
  border-bottom: 1px solid #ccc;
}
.pageInfo > p {
  font-size: 12px;
  color: var(--subTitleGray);
  margin-top: 5px;
  padding-bottom: 8px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 5px;
}
ul.breadcrumbs {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 500;
}
ul.breadcrumbs > li {
  cursor: pointer;
  margin-right: 5px;
}
ul.breadcrumbs > li.active {
  font-weight: 400;
  cursor: default;
  color: var(--subTitleGray);
}
.uiTxt.uploadFile input {
  padding-top: 4px;
  padding-left: 4px;
  height: 30px;
}

.gridOption {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}
.gridOption .uiTxt {
  width: 40%;
  margin-right: 10px;
}
.gridOption .iconBtn {
  padding: 5px;
  margin-right: 10px;
}
.gridOption .iconBtn svg {
  font-size: 18px;
}
.options button {
  padding: 8px;
}
.options button svg {
  font-size: 17px;
}

.activeColumn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.activeColumn svg {
  font-size: 18px;
}

.pageHeader {
  background: #fff !important;
  z-index: 9999;
}
.headerMenuList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 75%;
  justify-content: flex-end;
}
.headerMenu {
  color: #000 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}
.logoContainer {
  width: 25%;
  cursor: pointer;
}
.logoContainer > img {
  height: 45px;
}
.copyright {
  background: #272727;
  color: #cdcdcd;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* TextBox */
.uiTxt {
  margin-top: 5px !important;
  width: 100%;
}
.uiTxt > div {
  height: 30px;
  background: #fff;
}
.uiTxt input {
  font-size: 0.8rem;
  padding-top: 18px;
  padding-left: 10px;
  box-sizing: border-box;
}
.uiTxt > label {
  font-size: 1rem;
  top: -8px;
  left: -5px;
}
.uiTxt > label[data-shrink="true"] {
  top: -2px;
}

/* Button */
.uiBtn {
  border: none !important;
  font-weight: 500 !important;
}

.uiLoader svg {
  color: var(--primaryColor);
}

.uiAccordion {
  background: #f1f1f1 !important;
  box-shadow: 1px 2px 5px #d7d7d7 !important;
}
.uiAccordionContent {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  background: #fff !important;
}
.uiAccordionTitle > div {
  margin: 0px !important;
}
.uiAccordionTitle > div h4 {
  margin: 0px !important;
}

.uiChkBox > span:last-child {
  font-size: 0.8rem;
  position: relative;
  top: 1px;
}

.uiRadioGroup span > svg {
  font-size: 16px;
}
.uiRadioGroup label > span:last-child {
  font-size: 16px;
  position: relative;
  left: -2px;
  top: 1px;
}

.uiSwitchBtn > span:last-child {
  font-size: 14px;
  position: relative;
  top: 1px;
}

.uiTabs {
  min-height: 30px !important;
}
.uiTabs button {
  color: var(--primaryColor) !important;
  padding: 0px !important;
  min-height: 30px;
  font-size: 14px;
  letter-spacing: 2px;
}
.uiTabs > div > span {
  background: var(--primaryColor);
}

.uiPopOver {
  overflow: visible;
  z-index: 999999 !important;
}
.uiPopOver ul {
  padding: 0px;
}

.font12 {
  font-size: 12px;
}
.font14 {
  font-size: 14px;
}

.alignCenter {
  display: flex;
  justify-content: center;
}

.zoomBox {
  overflow: hidden;
}
img.zoomer {
  transition: transform 1s;
}
img.zoomer:hover {
  transform: scale(1.5);
}

.subTitle {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 25px;
  border-bottom: 1px solid var(--dimGray);
  padding-bottom: 10px;
}
.subTitle > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.subTitle > div:first-child {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.subTitle > div:last-child {
  width: 90%;
}
.subTitle > div:first-child > img {
  width: 50px;
  height: 50px;
  background-color: var(--dimGray);
  border-radius: 50%;
}
.subTitle h4 {
  margin: 0px;
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}
.subTitle p {
  margin: 0px;
  margin-bottom: 4px;
  color: var(--subTitleGray);
}

.uiFooter {
  display: flex;
  flex-direction: column;
  background: #000;
}
.uiFooterMenus h4,
.uiFooterMenus a {
  color: #fff;
}
ul.footerMenuList > li {
  color: #fff;
  font-weight: 500;
}
.footerMenuList > li svg {
  font-size: 12px;
}

img.responsive,
video.responsive {
  width: 100%;
}

.swiper-pagination {
  position: relative !important;
}
.swiper-button-prev {
  top: 44% !important;
}
.swiper-button-next {
  top: 46% !important;
}

.uiFooterMenus .container > div {
  width: 33%;
}
.uiFooterMenus .container {
  display: flex;
  justify-content: space-between;
}
.uiFooterMenus .container > div:first-child {
  width: 20%;
}
.uiFooterMenus .container > div:nth-child(2) {
  width: 40%;
}
.uiFooterMenus .container > div:nth-child(3) {
  width: 30%;
}
.uiFooter .blogs {
  width: 100%;
}
.uiFooter .blog {
  display: flex;
  flex-direction: row;
}
.uiFooter .blog > div:first-child {
  width: 40%;
}
.uiFooter .blog > div:last-child {
  width: 60%;
  padding-left: 10px;
}
.blog h4 {
  margin-top: 0px;
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: 500;
}
.blog p {
  margin: 0px;
  font-size: 12px;
  color: #fff;
  text-align: justify;
}

.subscribe {
  display: flex;
  flex-direction: column;
  color: #fff;
  font-size: 14px;
}
.subscribeInput {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.subscribeInput .uiTxt {
  background: #fff;
  border-radius: 4px;
}
.subscribeInput button {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: 35px;
  margin-left: 15px;
}

.social {
  display: flex;
  justify-content: center;
}
.social svg {
  color: #fff;
  font-size: 24px;
}
.social ul {
  display: flex;
}
.social ul > li {
  padding: 4px;
  margin-right: 15px;
}

.uiAutoTxt {
  margin-top: 5px;
}
.uiAutoTxt .MuiChip-root {
  background: #ddd;
  border-radius: 5px;
  position: relative;
  top: -4px;
  left: -3px;
  padding: 0px !important;
}
.uiAutoTxt > div > div {
  height: 30px;
}
.uiAutoTxt input {
  padding-top: 0px !important;
  margin-top: 0px;
  font-size: 0.8rem;
}
.uiAutoTxt div {
  background: #fff;
  border-radius: 3px;
}
.uiAutoTxt label {
  font-size: 0.8rem;
  color: #686868;
}
.uiTxt.multiLine > div {
  height: auto;
  font-size: 0.8rem;
}
.uiTxt > div.Mui-focused {
  box-shadow: 1px 1px 4px 2px #6da7f3 !important;
}
.uiAutoTxt > div > div.Mui-focused {
  box-shadow: 1px 1px 4px 2px #6da7f3 !important;
}
.autocompletePopper {
  border: 1px solid #c6c6c6;
  border-top: none;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.autocompletePopper li {
  font-size: 1rem !important;
}

.loadingBox {
  height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.groupBox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
}
.groupBox > .groupTitle {
  width: 100%;
}
.groupBox > .groupTitle > h4 {
  font-size: 16px;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 10px;
  background: #e7e7e7;
  padding: 5px 10px;
}
.formRowWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.formRowWrapper .uiAutoTxt {
  margin-top: 6px;
}
.formRowWrapper > .formGroup {
  width: 25%;
}

.favBtn {
  position: absolute;
  z-index: 999;
  right: 0;
}
.favBtn svg {
  color: #fff;
}
.favBtn.active svg {
  color: var(--likeBtn);
}
.uiDialog {
  z-index: 1300 !important;
}

.rangeSlider > p {
  font-size: 14px;
  margin: 0px;
  position: relative;
  left: -2px;
}

.projectFiles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 5px;
}
.projectFiles > div {
  width: 150px;
  height: 100px;
  border: 1px solid #ccc;
  box-shadow: 1px 2px 5px #d3d3d3;
  border-radius: 4px;
  margin-right: 20px;
  margin-bottom: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

table#reportGrid.purpleBtm tbody tr:last-child {
  background: purple;
  color: #fff;
}

.uiTxt input[type="file"] {
  position: relative;
  top: -2px;
  left: -4px;
  box-sizing: initial;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  color: #000;
  white-space: normal !important;
  word-wrap: break-word !important;
  padding: 5px 0px !important;
  padding-left: 5px !important;
}

table.dx-datagrid-table tbody > tr {
  border-bottom: 1px solid #e7e7e7 !important;
}
div.resourcePlanning .dx-toolbar-after .dx-toolbar-item:first-child {
  display: none;
}

.chat {
  background: #e5e5e5;
  border-radius: 10px;
  padding: 5px 18px;
  margin-bottom: 10px;
  position: relative;
  min-height: 65px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.chat::after {
  position: absolute;
  top: 10px;
  left: -20px;
  content: "";
  width: 10px;
  border-right: 15px solid #e5e5e5;
  border-bottom: 10px solid transparent;
  border-left: 0px solid green;
  border-top: 10px solid transparent;
}
.chat > h4 {
  margin: 0px;
  margin-bottom: 5px;
  font-size: 16px;
}
.chat > p {
  margin: 0px;
  font-size: 14px;
  color: red;
}

.formGroupBox {
  border: 1px solid #0034ad42;
  border-radius: 5px;
  margin-bottom: 2rem;
  box-shadow: 1px 2px 10px #e7e7e7;
  padding-bottom: 0.5rem;
}
.formGroupTitle {
  background: #0034ad42;
  padding: 0.6rem 1rem;
  margin-bottom: 1rem;
}
.formGroupTitle h4 {
  margin: 0;
  color: var(--primaryColor);
  text-transform: uppercase;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  flex-direction: row;
}
.flexRowCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.flexRowFullCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.flexRowJustify {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.flexRowLeft {
  display: flex;
  flex-direction: row;
    justify-content: flex-start;
}
.flexRowRight {
  display: flex;
  flex-direction: row;
    justify-content: flex-end;
}
.flexbtnjustify {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.flexRowCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.submitBtnWrapper {
  display: flex;
  justify-content: right;
  margin-top: 25px;
}

.excelGridWrapper {
  width: 98%;
  height: 80%;
  display: flex;
  margin-left: -12px;
  justify-content: center;
  margin-top: 0;
}

.flexRowAround {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

/* Apply custom styling to the scrollbar */
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  height: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar handle */
  border-radius: 5px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar handle on hover */
}

.marginTop2 {
  margin-top: 2rem;
}

.graphs {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.graphBox {
  display: flex;
}
.graphBox > div {
  border: 1px solid #ccc;
}

.crmDashboard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
}
.crmDashboard .excelGrid {
  width: 100%;
}
.textShadow {
  color: #fff !important;
  text-shadow: 1px 1px 5px #000;
}
.crmDashboard .textShadow {
  color: #fff !important;
  text-shadow: 1px 1px 5px #000;
}
.mapLeadGrid .excelGrid {
  width: 100%;
}
.mapLeadGrid {
  width: 99%;
  margin: auto;
}

.pageCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chipBtn {
  font-weight: 500;
  min-width: 10rem;
}
.chipBtn.red {
  color: #fff !important;
  background: var(--red) !important;
  opacity: 1 !important;
}
.chipBtn.green {
  color: #fff !important;
  background: var(--green) !important;
  opacity: 1 !important;
}
.chipBtn.limeGreen {
  color: #000 !important;
  background: var(--tertiaryColor) !important;
  opacity: 1 !important;
}

.required > label {
  color: var(--red) !important;
}
.mandatoryBox {
  background: #f8d7da;
  font-size: 0.8rem;
  font-weight: normal;
  border-radius: 0.4rem;
  padding: 0.5rem 0.8rem;
  border: 0.1rem solid #f1aeb5;
  color: #58151c;
}
.mandatoryBox ul {
  margin: 0px;
  padding-left: 1rem;
}
.mandatoryBox ul > li {
  margin-bottom: 0.1rem;
}
.mandatoryBox ul > li:last-child {
  margin-bottom: 0rem;
}
.formSection {
  margin-top: 0rem;
  margin-bottom: 1.5rem;
  border-radius: 0.3rem;
  border-color: #fbfbfb;
  border-width: 0.2rem;
  padding-left: 0px;
  padding-right: 0px;
}
.formSectionLegend {
  color: var(--primaryColor);
  border-color: var(--borderColor);
  /* padding-top: 1rem; */
  padding: 1rem;
  font-size: 1rem;
  font-weight: 600;
  text-transform: capitalize;
}
.ordersLifecycle {
  display: flex;
  justify-content: center;
  padding-top: 2.5vh;
}
.ordersLifecycle > img {
  width: 60%;
}

.CRMLifecycle {
  display: grid;
  place-items: center;
  padding-top: 2.5vh;
}
.CRMLifecycle > img {
  width: 80%;
}

.modalTitle {
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 500;
  color: var(--primaryColor);
  padding-bottom: 8px;
}
.semiBold {
  font-weight: 500;
}
.subHeadings {
  margin-bottom: 0px;
  font-size: 1.1rem;
  /* background-color: var(--dimGray);
  width: 87.2%;
  padding: 5px 0 5px 5px;
  border-radius: 3px; */
}

button.stepperBtn {
  height: 0px;
}

.required>label {
  color: red;
}

.pL10{
  padding-left: 10px;
}

.formGroup>label {
  font-size: 0.8rem;
  font-weight: 600;
}

.welcomeBox {
  display: flex;
  position: absolute;
  right: 1.5rem;
  background: #ffffffe3;
  border-radius: 0.5rem;
  padding: 1rem;
  flex-direction: row;
  align-items: center;
  box-shadow: 1px 2px 5px #6f6f6f;
}
.welcomeBox h4 {
  margin: 0px !important;
  color: var(--primaryColor);
  text-shadow: 1px 2px 5px #fff;
}

.box {
  width: 94%;
  border: 1px solid black;
  border-radius: 1rem;
  padding: 20px;
  margin: 10px;
 
}

.formSales {
  margin-top: 0rem;
  margin-bottom: 1.5rem;
  border-radius: 0.3rem;
  border-color: #fbfbfb;
  border-width: 0.2rem;
  padding-left: 0px;
  padding-right: 0px;
  width:98%;
  justify-content: center;
  align-self: center;
}
.flexbtnLoad {
  display: flex;
  flex-direction: row;
  justify-content: right;
  gap: 20px;
  
}
.fileUpload {
  position: relative;
  margin-top: 1rem;
  margin-right: 5rem;
}

.formNote {
  font-size: 0.8rem;
  background-color: #cfe2ff;
  color: #052c65;
  border: 0.1rem solid #9ec5fe;
  border-radius: 0.4rem;
  padding: 0.5rem 0.8rem;
  margin-bottom: 0.9rem;
}
.formNote ul {
  margin: 0px;
  padding-left: 1rem;
}
.formNote ul > li {
  margin-bottom: 0.1rem;
  font-weight: normal;
}

.textShadowBlack {
  color: #070707 !important;
  text-shadow: 1px 1px 5px #000;
}

.filterBorder{
  border: 2px solid var(--primaryColor);
  border-radius: 5px;
}

.excelBtnWrapper{
  border: 1px solid var(--primaryColor);
  border-radius: 5px;
  margin-right: 15px;
}

.uiAccordionTitle {
  min-height: 35px ;
  border: 1px solid var(--primaryColor)  ;
  border-radius: 5px  ;
  margin-right: 15px  ;
}

select.simpleDropdown {
  min-height: 25px;
  min-width: 50px;
  padding-left: 2px;
}