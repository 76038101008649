.streetView {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
}
.mapShops {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  height: 99.5%;
  overflow: auto;
}
.mapShops > h4 {
  color: var(--primaryColor);
  margin: 0px;
  margin-top: 0.8rem;
  margin-bottom: 1rem;
}
.shop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  margin-bottom: 0.8rem;
  width: 95%;
  border-bottom: 1px solid #ccc;
  padding-bottom: 0.8rem;
  cursor: pointer;
}
.shop:last-child {
  border: none;
}
.shop > div:first-child {
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.shop > div:first-child > img {
  width: 70%;
}
.shop > div:last-child {
  width: 80%;
}
.shop > div:last-child > .shopName {
  margin: 0px;
  font-size: 1rem;
}
.shop > div:last-child > .details {
  margin: 0px;
  font-size: 0.8rem;
}

.mapFilters {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 0.3rem;
}
.shopCategoryfilters {
  width: 65%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0.5rem 0rem;
  padding-right: 0.5rem;
}
.shopCategoryfilters > div {
  background: #fff;
  padding: 0.3rem 0.7rem;
  cursor: pointer;
  margin-left: 1rem;
  font-size: 1rem;
  border-radius: 5px;
  text-align: center;
}
.shopCategoryfilters > div.active {
  color: var(--primaryColor);
  font-weight: bold;
  border: 1px solid var(--primaryColor);
}

.autocomplete-dropdown-container {
  position: absolute;
  overflow: auto;
  height: 200px;
}
.mapOverLay {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid #ccc;
  z-index: 99999999;
  top: 0;
  color: #fff;
  left: 0;
  font-size: 20px;
}
.progressBar {
  /* position: fixed; */
  z-index: 9999999999;
  width: 100%;
  top: 0;
  left: 0;
}
